import React from 'react'
import { Link } from 'gatsby'
import prayas from "../assets/images/prayas.jpg";
import prabhat from "../assets/images/prabhat.jpg";
import samarpan from "../assets/images/samarpan.jpg";
import ashayein from "../assets/images/aashayein.jpg";


const MajarEventList = () => {
  return (
    <section className='contact-info-one mb-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-3'>
            <div className='blog-one__single'>
              <div className='blog-one__content text-center eventBlock'>
                <img className="blog-one__image eventImage"
                  src={prayas}
                  alt=''
                />
                <h2 className='blog-one__title'>
                  <a href='#'>Prayas</a>
                </h2>
                <p className='blog-one__text'>Joy of Giving</p>
                <div className='cta-one__btn-block'>
                  <Link
                    to={'/prayas'}
                    className='thm-btn cta-one__btn btncolor'
                  >
                    Know More
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-3'>
            <div className='blog-one__single'>
              <div className='blog-one__content text-center eventBlock'>
                <img className="blog-one__image eventImage"
                  src={prabhat}
                  alt=''
                />
                <h2 className='blog-one__title'>
                  <a href='#'>Prabhat</a>
                </h2>
                <p className='blog-one__text'>The Knowledge Fair</p>
                <div className='cta-one__btn-block'>
                  <Link
                    to={'/prabhat'}
                    className='thm-btn cta-one__btn btncolor'
                  >
                    Know More
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-3'>
            <div className='blog-one__single'>
              <div className='blog-one__content text-center eventBlock'>
                <img className="blog-one__image eventImage"
                  src={samarpan}
                  alt=''
                />                <h2 className='blog-one__title'>
                  <a href='#'>Samarpan</a>
                </h2>
                <p className='blog-one__text'>The Grandparent's Day</p>
                <div className='cta-one__btn-block'>
                  <Link
                    to={'/samarpan'}
                    className='thm-btn cta-one__btn btncolor'
                  >
                    Know More
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-3'>
            <div className='blog-one__single'>
            <div className='blog-one__content text-center eventBlock'>
                <img className="blog-one__image eventImage"
                  src={ashayein}
                  alt=''
                />
                <h2 className='blog-one__title'>
                  <a href='#'>Aashayein</a>
                </h2>
                <p className='blog-one__text'>The Annual Function</p>
                <div className='cta-one__btn-block'>
                  <Link
                    to={'/aashayein'}
                    className='thm-btn cta-one__btn btncolor'
                  >
                    Know More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default MajarEventList
